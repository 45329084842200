import { graphql, PageProps } from "gatsby";
import React from "react";

import Layout from "../../components/Layout/layout";
import { SEO } from "../../components/SEO/SEO";

const CookiePolicy = ({ data }: PageProps<Queries.CookiePolicyQuery>) => {
  return (
    <Layout>
      <div className="docs">
        <h1>POL&Iacute;TICA DE COOKIES</h1>
        <h2>INFORMACI&Oacute;N SOBRE COOKIES</h2>
        <p>
          Conforme con la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la informaci&oacute;n y de
          comercio electr&oacute;nico (LSSI), en relaci&oacute;n con el Reglamento (UE) 2016/679 del Parlamento Europeo
          y del Consejo, de 27 de abril de 2016, General de Protecci&oacute;n de Datos (GDPR) y la Ley Org&aacute;nica
          3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos y Garant&iacute;a de los Derechos Digitales
          (LOPDGDD), es obligado obtener el consentimiento expreso del usuario de todas las p&aacute;ginas web que usan
          cookies prescindibles, antes de que este navegue por ellas.
        </p>
        <h3>&iquest;QU&Eacute; SON LAS COOKIES?</h3>
        <p>
          Las cookies y otras tecnolog&iacute;as similares tales como local shared objects, flash cookies o
          p&iacute;xeles, son herramientas empleadas por los servidores Web para almacenar y recuperar
          informaci&oacute;n acerca de sus visitantes, as&iacute; como para ofrecer un correcto funcionamiento del
          sitio.
        </p>
        <p>
          Mediante el uso de estos dispositivos se permite al servidor Web recordar algunos datos concernientes al
          usuario, como sus preferencias para la visualizaci&oacute;n de las p&aacute;ginas de ese servidor, nombre y
          contrase&ntilde;a, productos que m&aacute;s le interesan, etc.
        </p>
        <h3>COOKIES AFECTADAS POR LA NORMATIVA Y COOKIES EXCEPTUADAS</h3>
        <p>
          Seg&uacute;n la directiva de la UE, las cookies que requieren el consentimiento informado por parte del
          usuario son las cookies de anal&iacute;tica y las de publicidad y afiliaci&oacute;n, quedando exceptuadas las
          de car&aacute;cter t&eacute;cnico y las necesarias para el funcionamiento del sitio web o la prestaci&oacute;n
          de servicios expresamente solicitados por el usuario.
        </p>
        <h2>TIPOS DE COOKIES</h2>
        <h3>
          <strong>SEG&Uacute;N LA FINALIDAD</strong>
        </h3>
        <p>
          Cookies <strong>t&eacute;cnicas y funcionales</strong>: son aquellas que permiten al usuario la
          navegaci&oacute;n a trav&eacute;s de una p&aacute;gina web, plataforma o aplicaci&oacute;n y la
          utilizaci&oacute;n de las diferentes opciones o servicios que en ella existan.
        </p>
        <p>
          Cookies <strong>anal&iacute;ticas</strong>: son aquellas que permiten al responsable de las mismas el
          seguimiento y an&aacute;lisis del comportamiento de los usuarios de los sitios web a los que est&aacute;n
          vinculadas. La informaci&oacute;n recogida mediante este tipo de cookies se utiliza en la medici&oacute;n de
          la actividad de los sitios web, aplicaci&oacute;n o plataforma y para la elaboraci&oacute;n de perfiles de
          navegaci&oacute;n de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir
          mejoras en funci&oacute;n del an&aacute;lisis de los datos de uso que hacen los usuarios del servicio.
        </p>
        <p>
          &nbsp;Cookies <strong>publicitarias:</strong> son aquellas que permiten la gesti&oacute;n, de la forma
          m&aacute;s eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una
          p&aacute;gina web, aplicaci&oacute;n o plataforma desde la que presta el servicio solicitado en base a
          criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.
        </p>
        <p>
          &nbsp;Cookies <strong>de publicidad comportamental</strong>: recogen informaci&oacute;n sobre las preferencias
          y elecciones personales del usuario (retargeting) para permitir la gesti&oacute;n, de la forma m&aacute;s
          eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una p&aacute;gina
          web, aplicaci&oacute;n o plataforma desde la que presta el servicio solicitado.
        </p>
        <p>
          &nbsp;Cookies <strong>sociales</strong>: son establecidas por las plataformas de redes sociales en los
          servicios para permitirle compartir contenido con sus amigos y redes. Las plataformas de medios sociales
          tienen la capacidad de rastrear su actividad en l&iacute;nea fuera de los Servicios. Esto puede afectar al
          contenido y los mensajes que ve en otros servicios que visita. Cookies <strong>de afiliados</strong>: permiten
          hacer un seguimiento de las visitas procedentes de otras webs, con las que el sitio web establece un contrato
          de afiliaci&oacute;n (empresas de afiliaci&oacute;n).
        </p>
        <p>
          Cookies <strong>de seguridad</strong>: almacenan informaci&oacute;n cifrada para evitar que los datos
          guardados en ellas sean vulnerables a ataques maliciosos de terceros.
        </p>
        <h3>SEG&Uacute;N LA PROPIEDAD</h3>
        <p>
          Cookies<strong> propias</strong>: son aquellas que se env&iacute;an al equipo terminal del usuario desde un
          equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el
          usuario.
        </p>
        <p>
          Cookies <strong>de terceros:</strong> son aquellas que se env&iacute;an al equipo terminal del usuario desde
          un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos
          trav&eacute;s de las cookies.
        </p>
        <h3>SEG&Uacute;N EL PLAZO DE CONSERVACI&Oacute;N</h3>
        <p>
          Cookies<strong> de sesi&oacute;n:</strong> son un tipo de cookies dise&ntilde;adas para recabar y almacenar
          datos mientras el usuario accede a una p&aacute;gina web.
        </p>
        <p>
          Cookies<strong> persistentes:</strong> son un tipo de cookies en el que los datos siguen almacenados en el
          terminal y pueden ser accedidos y tratados durante un per&iacute;odo definido por el responsable de la cookie,
          y que puede ir de unos minutos a varios a&ntilde;os.
        </p>
        <p>TRATAMIENTO DE DATOS PERSONALES</p>
        <p>
          D.I.R.E USIETO, S.A. es el <strong>Responsable del tratamiento</strong> de los datos personales del{" "}
          <strong>Interesado </strong>y le informa de que estos datos ser&aacute;n tratados de conformidad con lo
          dispuesto en el Reglamento (UE) 2016/679, de 27 de abril de 2016 (GDPR), por lo que se le facilita la
          siguiente informaci&oacute;n del tratamiento:
        </p>
        <p>
          <strong>Fines del tratamiento: </strong>seg&uacute;n se especifica en el apartado de cookies que se utilizan
          en este sitio web.
        </p>
        <p>
          <strong>Legitimaci&oacute;n del tratamiento</strong>: salvo en los casos en los que resulte necesario para la
          navegaci&oacute;n por la web, por consentimiento del interesado (art. 6.1 GDPR).
        </p>
        <p>
          <strong>Criterios de conservaci&oacute;n de los datos</strong>: seg&uacute;n se especifica en el apartado de
          cookies utilizadas en la web.
        </p>
        <p>
          <strong>Comunicaci&oacute;n de los datos</strong>: no se comunicar&aacute;n los datos a terceros, excepto en
          cookies propiedad de terceros o por obligaci&oacute;n legal.
        </p>
        <p>
          <strong>Derechos que asisten al Interesado</strong>:
        </p>
        <ul>
          <li>Derecho a retirar el consentimiento en cualquier momento.</li>
          <li>
            Derecho de acceso, rectificaci&oacute;n, portabilidad y supresi&oacute;n de sus datos, y de
            limitaci&oacute;n u oposici&oacute;n a su tratamiento.- Derecho a presentar una reclamaci&oacute;n ante la
            Autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente.
          </li>
        </ul>
        <p>
          <strong>Datos de contacto para ejercer sus derechos:</strong>
        </p>
        <p>D.I.R.E USIETO, S.A.. C/ PERTUSA, 16 - 50197 ZARAGOZA (Zaragoza). E-mail: info@grupousieto.com</p>
        <h2>COOKIES UTILIZADAS EN ESTE SITIO WEB</h2>
        <h3>COOKIES CONTROLADAS POR EL EDITOR</h3>
        <h4>
          <strong>T&eacute;cnicas y funcionales</strong>
        </h4>
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Propiedad</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Cookie</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Finalidad</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Plazo</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>grupousieto.com</p>
              </td>
              <td>
                <p>
                  <strong>PHPSESSID</strong>
                </p>
              </td>
              <td>
                <p>
                  Cookies generada por las aplicaciones basadas en el lenguaje PHP. Se trata de un identificador de
                  prop&oacute;sito general usado para mantener las variables de sesi&oacute;n de usuario. Normalmente es
                  un n&uacute;mero generado al azar, c&oacute;mo se utiliza puede ser espec&iacute;fica para el sitio,
                  pero un buen ejemplo es el mantenimiento de una sesi&oacute;n iniciada en el estado de un usuario
                  entre las p&aacute;ginas.
                </p>
              </td>
              <td>
                <p>Sesi&oacute;n</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h4>Anal&iacute;ticas</h4>
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Propiedad</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Cookie</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Finalidad</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Plazo</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>grupousieto.com</p>
              </td>
              <td>
                <p>
                  <strong>_ga</strong>
                </p>
              </td>
              <td>
                <p>ID utiliza para identificar a los usuarios</p>
              </td>
              <td>
                <p>en 2 a&ntilde;os</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>grupousieto.com</p>
              </td>
              <td>
                <p>
                  <strong>_gat</strong>
                </p>
              </td>
              <td>
                <p>
                  Se utiliza para monitorizar el n&uacute;mero de peticiones al servidor de Google Analytics cuando se
                  utiliza el Administrador de etiquetas Google
                </p>
              </td>
              <td>
                <p>Sesi&oacute;n</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>grupousieto.com</p>
              </td>
              <td>
                <p>
                  <strong>_gid</strong>
                </p>
              </td>
              <td>
                <p>
                  ID utiliza para identificar a los usuarios durante 24 horas despu&eacute;s de la &uacute;ltima
                  actividad
                </p>
              </td>
              <td>
                <p>en 20 horas</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h2>COOKIES DE TERCEROS</h2>
        <p>
          Los servicios de terceros son ajenos al control del editor. Los proveedores pueden modificar en todo momento
          sus condiciones de servicio, finalidad y utilizaci&oacute;n de las cookies, etc.
        </p>
        <p>
          <strong>Proveedores externos de este sitio web:</strong>
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Editor</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Pol&iacute;tica de privacidad</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Google Analytics</p>
              </td>
              <td>
                <p>
                  <a href="https://privacy.google.com/take-control.html">
                    https://privacy.google.com/take-control.html
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>PHP.net</p>
              </td>
              <td>
                <p>
                  <a href="https://www.php.net/privacy.php">https://www.php.net/privacy.php</a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>PANEL DE CONFIGURACI&Oacute;N DE COOKIES</p>
        <p>
          Desde este panel podr&aacute; configurar las cookies que el sitio web puede instalar en su navegador, excepto
          las cookies t&eacute;cnicas o funcionales que son necesarias para la navegaci&oacute;n y la utilizaci&oacute;n
          de las diferentes opciones o servicios que se ofrecen.
        </p>
        <p>
          <u>Panel de cookies</u>
        </p>
        <p>&nbsp;</p>
        <table>
          <tbody>
            <tr>
              <td colSpan={3}>
                <p>
                  <strong>C&Oacute;MO GESTIONAR LAS COOKIES DESDE EL NAVEGADOR</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p>
                  <strong>Eliminar las cookies del dispositivo</strong>
                </p>
              </td>
              <td>
                <p>
                  Las cookies que ya est&aacute;n en un dispositivo se pueden eliminar borrando el historial del
                  navegador, con lo que se suprimen las cookies de todos los sitios web visitados. Sin embargo,
                  tambi&eacute;n se puede perder parte de la informaci&oacute;n guardada (por ejemplo, los datos de
                  inicio de sesi&oacute;n o las preferencias de sitio web).
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p>
                  <strong>Gestionar las cookies espec&iacute;ficas del sitio</strong>
                </p>
              </td>
              <td>
                <p>
                  Para tener un control m&aacute;s preciso de las cookies espec&iacute;ficas de cada sitio, los usuarios
                  pueden ajustar su configuraci&oacute;n de privacidad y cookies en el navegador.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p>
                  <strong>Bloquear las cookies</strong>
                </p>
              </td>
              <td>
                <p>
                  Aunque la mayor&iacute;a de los navegadores modernos se pueden configurar para evitar que se instalen
                  cookies en los dispositivos, eso puede obligar al ajuste manual de determinadas preferencias cada vez
                  que se visite un sitio o p&aacute;gina. Adem&aacute;s, algunos servicios y caracter&iacute;sticas
                  pueden no funcionar correctamente (por ejemplo, los inicios de sesi&oacute;n con perfil).
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <p>
                  <strong>C&Oacute;MO ELIMINAR LAS COOKIES DE LOS NAVEGADORES M&Aacute;S COMUNES</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Chrome</strong>
                </p>
              </td>
              <td colSpan={2}>
                <p>
                  <a href="http://support.google.com/chrome/answer/95647?hl=es">
                    http://support.google.com/chrome/answer/95647?hl=es
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Edge</strong>
                </p>
              </td>
              <td colSpan={2}>
                <p>
                  <a href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
                    https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Explorer</strong>
                </p>
              </td>
              <td colSpan={2}>
                <p>
                  <a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer">
                    https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Firefox</strong>
                </p>
              </td>
              <td colSpan={2}>
                <p>
                  <a href="https://www.mozilla.org/es-ES/privacy/websites/#cookies">
                    https://www.mozilla.org/es-ES/privacy/websites/#cookies
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Safari</strong>
                </p>
              </td>
              <td colSpan={2}>
                <p>
                  <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">
                    https://support.apple.com/es-es/guide/safari/sfri11471/mac
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Opera</strong>
                </p>
              </td>
              <td colSpan={2}>
                <p>
                  <a href="https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData">
                    https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default CookiePolicy;

export const Head = () => <SEO />;

export const query = graphql`
  query CookiePolicy {
    sanityDocumentData {
      globals {
        email
        phone
      }
      foodService {
        phone
        email
      }
      logistics {
        email
        email
      }
      legalName
      urlSite
    }
  }
`;
