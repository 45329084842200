export const defaultTitle = "Grupo Usieto - Líderes en logística y distribución comercial";
export const defaultDescription = "Más de 30 años de experiencia en logística y distribución comercial de productos refrigerados y congelados. Entra y conócenos, podemos ayudarte.";
export const author = "Grupo Usieto";
export const siteUrl = "https://www.grupousieto.com/";
export const legalName = "D.I.R.E. USIETO. S.A.";
export const comercialName = "Grupo Usieto";
export const blogName = "Noticias Grupo Usieto";
export const logotypeUrl = "https://grupo-usieto.netlify.app/static/14deea68c725d15c3282d02a77d1efaf/54b01/logo.webp";
export const foundingDate = "2022";
export const contact: { email: string; phone: string } = {
  email: "info@grupousieto.com",
  phone: "976470287",
};
export const socialLinks: {
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  google: string;
} = {
  linkedin: "https://www.linkedin.com/company/grupo-usieto-log%C3%ADstica-y-distribuci%C3%B3n-comercial/",
  google: "https://www.youtube.com/channel/UCDvm-f5nNKSlYyyTqqna4gA",
};
const social: {
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  telegram: string;
} = {
  facebook: "@",
  twitter: "@",
  linkedin: "@",
  instagram: "@",
  telegram: "@",
};
export const address: {
  streetAddress: string;
  city: string;
  region: string;
  country: string;
  zipCode: string;
} = {
  streetAddress: "Polígono Plaza C/ PERTUSA, 16",
  city: "Zaragoza",
  region: "Zaragoza",
  country: "España",
  zipCode: "50197",
};
