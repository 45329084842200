import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";

import { defaultDescription, defaultTitle, socialLinks } from "../../data/config";

type PropsSeo = PropsWithChildren<{
  title?: string | null | undefined;
  description?: string | null | undefined;
  location?: string | null | undefined;
  robots?: string | null | undefined;
  canonical?: string | null | undefined;
  imageAbsolutePath?: string | null | undefined;
}>;

export const SEO: React.FC<PropsSeo> = (props: PropsSeo) => {
  const { sanityDocumentData: data } = useStaticQuery(graphql`
    query SEO {
      sanityDocumentData {
        urlSite
      }
    }
  `);
  const location = useLocation();

  const title = props.title != null ? props.title : defaultTitle;
  const description = props.description != null ? props.description : defaultDescription;
  const robots = props.robots != null ? props.robots : "index, follow";
  const image = props.imageAbsolutePath ? props.imageAbsolutePath : `${data.urlSite}pictures/thumbnail.png`; //statics

  const getCanonical = props.canonical ? props.canonical : `${location.pathname}`;
  const canonicalComposition = `${data.urlSite}/${getCanonical}/`;

  const canonical = canonicalComposition.replace(/[\\/]{2,}/g, "/").replace("https:/", "https://");

  return (
    <>
      <meta
        name="description"
        content={description}
      />
      <meta
        name="image"
        content={image}
      />

      <meta
        property="og:url"
        content={`${canonical}`}
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:title"
        content={title}
      />
      <meta
        property="og:description"
        content={description}
      />
      <meta
        property="og:image"
        content={image}
      />
      <meta
        property="fb:app_id"
        content={socialLinks.facebook}
      />

      <meta
        name="twitter:card"
        content="summary"
      />
      <meta
        name="twitter:creator"
        content={socialLinks.twitter}
      />
      <meta
        name="twitter:site"
        content={socialLinks.twitter}
      />
      <meta
        name="twitter:title"
        content={title}
      />
      <meta
        name="twitter:description"
        content={description}
      />
      <meta
        name="twitter:image:src"
        content={image}
      />
      <link
        rel="publisher"
        href={socialLinks.google}
      />
      <link
        rel="canonical"
        href={`${canonical}`}
      />
      <title>{title}</title>
      <html
        lang="es"
        dir="ltr"
      />
      <meta
        name="robots"
        content={robots}
      />
    </>
  );
};
